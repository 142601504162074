import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

import { Separator } from "@/components/Separator/Separator";
import { useLicenseQuery } from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import { emptyStateWrapper } from "./License.css";
import { LicenseTable } from "./LicenseTable";

export const License = () => {
  const { name = "" } = useParams<{ name: string }>();

  const [{ data: licenseData, fetching }] = useLicenseQuery({
    pause: !name,
    variables: {
      name,
    },
  });

  const noData = !licenseData && !fetching;

  return (
    <>
      <Helmet>
        <title>{name} license - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title={`${name} license`}
        description={`List of all packages and their corresponding repositories that use ${name} license.`}
      />
      <Separator />
      {noData ? (
        <div className={emptyStateWrapper}>
          No data available. Make sure the name of the license is correct.
        </div>
      ) : (
        <LicenseTable data={licenseData} />
      )}
    </>
  );
};
