import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { BarChart } from "@/components/BarChart/BarChart";
import { Button } from "@/components/Button/Button";
import { Card } from "@/components/Card/Card";
import { ChartCard } from "@/components/ChartCard/ChartCard";
import Combobox, { ComboboxValue } from "@/components/Combobox/Combobox";
import { Icon } from "@/components/Icon/Icon";
import { Separator } from "@/components/Separator/Separator";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { RepositoryState } from "@/generated/graphql";
import { AppLayout } from "@/layouts/AppLayout/AppLayout";

import {
  useAnalyticsQuery,
  useOrganizationsQuery,
} from "../../../generated/graphql";
import {
  actionsWrapper,
  cardsWrapperStyles,
  emptyStateLink,
  emptyStateWrapper,
  fullRowStyles,
} from "./Dashboard.css";

const DEFAULT_DAYS = 30 * 6;

export const Dashboard = () => {
  const [selectedDays, setSelectedDays] = useState<number>(DEFAULT_DAYS);
  const [selectedRepositoryId, setSelectedRepositoryId] = useState<string>("");
  const [{ data, fetching }] = useAnalyticsQuery({
    variables: {
      repositoryId: selectedRepositoryId ?? "",
      days: selectedDays,
    },
  });
  const [{ data: organizationsData }] = useOrganizationsQuery();

  const updatedCurrentData = data?.analytics.updated.current;
  const updatedDiffData = data?.analytics.updated.diff || 0;
  const updatedDiffDirection = updatedDiffData > 0 ? "up" : "down";
  const updatedDiffColor = updatedDiffData > 0 ? "green" : "red";
  const updatedChartData =
    data?.analytics?.updated?.history.map((day) => ({
      name: day.date,
      value: day.value,
    })) ?? [];

  const securityCurrentData = data?.analytics.security.current;
  const securityDiffData = data?.analytics.security.diff || 0;
  const securityDiffDirection = securityDiffData > 0 ? "up" : "down";
  const securityDiffColor = updatedDiffData > 0 ? "green" : "red";
  const securityChartData =
    data?.analytics?.security?.history.map((day) => ({
      name: day.date,
      value: day.value,
    })) ?? [];

  const majorCurrentData = data?.analytics.critical.current;
  const majorDiffData = data?.analytics.critical.diff || 0;
  const majorDiffDirection = majorDiffData > 0 ? "up" : "down";
  const majorDiffColor = majorDiffData > 0 ? "red" : "green";
  const majorChartData =
    data?.analytics?.critical?.history.map((day) => ({
      name: day.date,
      value: day.value,
    })) ?? [];

  const historyChartData =
    data?.analytics?.historical.map((day) => ({
      name: day.date,
      a: day.updated,
      b: day.outdated,
      c: day.critical,
      d: day.security,
    })) ?? [];

  const repositoriesSelector =
    organizationsData?.organizations.flatMap((org) =>
      org.repositories
        .filter((repo) => repo.state !== RepositoryState.Uninitialized)
        .map((repo) => ({
          label: `${org.name}/${repo.name}`,
          value: repo.id,
        }))
    ) ?? [];

  const dateSelector = [
    { label: "Last month", value: 30 },
    { label: "Last 6 months", value: 180 },
    { label: "Last year", value: 365 },
  ];

  const handleDateChange = (value: ComboboxValue) => {
    const val = value || DEFAULT_DAYS;
    setSelectedDays(Number(val));
  };

  const handleRepositoryChange = (value: ComboboxValue) => {
    setSelectedRepositoryId(value as string);
  };

  const noData = !data && !fetching;

  return (
    <>
      <Helmet>
        <title>Dashboard - DepsHub</title>
      </Helmet>
      <AppLayout.Header
        title="Dashboard"
        description="Dependencies summary and analytics."
        action={
          <div className={actionsWrapper}>
            <Tooltip
              content="Filter by repository"
              contentProps={{ side: "bottom" }}
            >
              <span>
                <Combobox
                  values={repositoriesSelector}
                  icon={<Icon.Repositories />}
                  placeholder="All repositories"
                  onChange={handleRepositoryChange}
                />
              </span>
            </Tooltip>
            <Tooltip content="Filter by date" contentProps={{ side: "bottom" }}>
              <span>
                <Combobox
                  values={dateSelector}
                  icon={<Icon.Calendar />}
                  placeholder="Last 6 months"
                  onChange={handleDateChange}
                />
              </span>
            </Tooltip>
          </div>
        }
      />
      <Separator />
      {noData && (
        <div className={emptyStateWrapper}>
          No data available. Make sure you have at least one repository
          connected.
          <Link to="/repositories" className={emptyStateLink}>
            <Button size="small">Connect</Button>
          </Link>
        </div>
      )}
      <div className={cardsWrapperStyles}>
        <ChartCard
          title="Security updates available"
          value={securityCurrentData}
          change={{
            value: formatNumber(securityDiffData),
            direction: securityDiffDirection,
            color: securityDiffColor,
          }}
          chartData={{
            data: securityChartData,
            goal: "min",
          }}
          dimmed={noData}
        />
        <ChartCard
          title="Packages up-to-date"
          value={updatedCurrentData + "%"}
          change={{
            value: formatNumber(updatedDiffData, true),
            direction: updatedDiffDirection,
            color: updatedDiffColor,
          }}
          chartData={{
            data: updatedChartData,
            goal: "max",
          }}
          dimmed={noData}
        />
        <ChartCard
          title="Major updates available"
          value={majorCurrentData}
          change={{
            value: formatNumber(majorDiffData),
            direction: majorDiffDirection,
            color: majorDiffColor,
          }}
          chartData={{
            data: majorChartData,
            goal: "min",
          }}
          dimmed={noData}
        />
      </div>
      <Card
        className={fullRowStyles}
        title="Updates history"
        description="Displays the ratio between updated and outdated dependencies."
        dimmed={noData}
      >
        <BarChart data={historyChartData} />
      </Card>
    </>
  );
};

function formatNumber(n: number, percentage = false) {
  const sign = n === 0 ? "" : n > 0 ? "+" : "-";

  return `${sign}${Math.abs(n)}${percentage ? "%" : ""}`;
}
