import {
  actionsStyles,
  descriptionStyles,
  iconStyles,
  titleStyles,
  wrapperStyles,
} from "./Header.css";

type HeaderProps = {
  title?: string;
  icon?: React.ReactNode;
  description: React.ReactNode;
  action?: React.ReactNode;
};

export const Header = ({
  title,
  description,
  icon,
  action = undefined,
}: HeaderProps) => {
  return (
    <div className={wrapperStyles}>
      <div>
        <div className={titleStyles}>
          {icon && <div className={iconStyles}>{icon}</div>}
          <div>{title}</div>
        </div>
        <div className={descriptionStyles}>{description}</div>
      </div>
      {action && <div className={actionsStyles}>{action}</div>}
    </div>
  );
};
