import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type Analytics = {
  __typename?: 'Analytics';
  critical: Stats;
  historical: Array<HistoricalStats>;
  security: Stats;
  updated: Stats;
};

export type Credit = {
  __typename?: 'Credit';
  contact: Array<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum DataSource {
  Npm = 'npm'
}

export type Dependency = {
  __typename?: 'Dependency';
  currentVersion: PackageVersion;
  id: Scalars['ID']['output'];
  package: Package;
  pullRequest?: Maybe<PullRequest>;
  state: DependencyState;
  type: DependencyType;
  upgrade?: Maybe<DependencyUpgrade>;
};

export enum DependencyState {
  Processed = 'processed',
  Updating = 'updating'
}

export enum DependencyType {
  Dev = 'dev',
  Regular = 'regular'
}

export type DependencyUpgrade = {
  __typename?: 'DependencyUpgrade';
  type: DependencyUpgradeType;
  version: Scalars['String']['output'];
  versions: Array<PackageVersion>;
};

export enum DependencyUpgradeType {
  Major = 'major',
  Minor = 'minor',
  Patch = 'patch',
  Security = 'security'
}

export type HistoricalStats = {
  __typename?: 'HistoricalStats';
  critical: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  outdated: Scalars['Int']['output'];
  security: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type HistoryPoint = {
  __typename?: 'HistoryPoint';
  date: Scalars['Date']['output'];
  value: Scalars['Int']['output'];
};

export type LicenseInfo = {
  __typename?: 'LicenseInfo';
  compliance: Scalars['Boolean']['output'];
  count: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LicensePackage = {
  __typename?: 'LicensePackage';
  isAccepted: Scalars['Boolean']['output'];
  license: Scalars['String']['output'];
  package: Package;
  repositoryId: Scalars['ID']['output'];
  repositoryName: Scalars['String']['output'];
};

export type Manifest = {
  __typename?: 'Manifest';
  dependencies: Array<Dependency>;
  id: Scalars['ID']['output'];
  isSupported: Scalars['Boolean']['output'];
  manifestType: ManifestType;
  path: Scalars['String']['output'];
  status: UpgradeStatus;
};

export enum ManifestType {
  Cargo = 'cargo',
  Composer = 'composer',
  Docker = 'docker',
  Gem = 'gem',
  Go = 'go',
  Gradle = 'gradle',
  Maven = 'maven',
  Mix = 'mix',
  Npm = 'npm',
  Pip = 'pip',
  Yarn = 'yarn'
}

export type Mutation = {
  __typename?: 'Mutation';
  createWorkspace: Workspace;
  initializeRepository: RepositoryPreview;
  inviteUsersToWorkspace: Workspace;
  revokeWorkspaceInvitation: Workspace;
  selectWorkspace: Workspace;
  updatePackages: Scalars['Boolean']['output'];
  updateWorkspaceSettings: Workspace;
};


export type MutationCreateWorkspaceArgs = {
  name: Scalars['String']['input'];
};


export type MutationInitializeRepositoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInviteUsersToWorkspaceArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type MutationRevokeWorkspaceInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationSelectWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdatePackagesArgs = {
  packages: Array<InputMaybe<UpdatePackagesInput>>;
};


export type MutationUpdateWorkspaceSettingsArgs = {
  settings: UpdateWorkspaceSettingsInput;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profileImgHref: Scalars['String']['output'];
  repositories: Array<RepositoryPreview>;
};

export type Package = {
  __typename?: 'Package';
  dataSource: DataSource;
  description: Scalars['String']['output'];
  history: Array<HistoryPoint>;
  homepage: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  keywords: Array<Scalars['String']['output']>;
  maintainers: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  readme: Scalars['String']['output'];
  repository: Scalars['String']['output'];
  score: Score;
  usedIn: Array<RepositoryPreview>;
  versions: Array<PackageVersion>;
};

export type PackageVersion = {
  __typename?: 'PackageVersion';
  changelog: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  rawVersion: Scalars['String']['output'];
  releasedAt: Scalars['Date']['output'];
};

export type PullRequest = {
  __typename?: 'PullRequest';
  createdAt: Scalars['Time']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  analytics: Analytics;
  license: Array<LicensePackage>;
  licenses: Array<LicenseInfo>;
  notifications: Scalars['Int']['output'];
  organizations: Array<Organization>;
  package: Package;
  packages: Array<Package>;
  repository: Repository;
  user: User;
  workspace: Workspace;
  workspaces: Array<Workspace>;
};


export type QueryAnalyticsArgs = {
  days: Scalars['Int']['input'];
  repositoryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLicenseArgs = {
  name: Scalars['String']['input'];
};


export type QueryPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRepositoryArgs = {
  id: Scalars['ID']['input'];
};

export enum ReferenceType {
  Advisory = 'advisory',
  Article = 'article',
  Detection = 'detection',
  Discussion = 'discussion',
  Evidence = 'evidence',
  Fix = 'fix',
  Git = 'git',
  Introduced = 'introduced',
  Package = 'package',
  Report = 'report',
  Web = 'web'
}

export type Repository = RepositoryBase & {
  __typename?: 'Repository';
  id: Scalars['ID']['output'];
  manifestFiles: Array<Manifest>;
  name: Scalars['String']['output'];
  settings: RepositorySettings;
  state: RepositoryState;
  status: UpgradeStatus;
  url: Scalars['String']['output'];
  vulnerabilities: Array<Vulnerability>;
};

export type RepositoryBase = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: RepositoryState;
  status: UpgradeStatus;
};

export type RepositoryPreview = RepositoryBase & {
  __typename?: 'RepositoryPreview';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: RepositoryState;
  status: UpgradeStatus;
};

export type RepositorySettings = {
  __typename?: 'RepositorySettings';
  isDefault: Scalars['Boolean']['output'];
  raw: Scalars['String']['output'];
};

export enum RepositoryState {
  Processed = 'processed',
  Processing = 'processing',
  Uninitialized = 'uninitialized'
}

export type Score = {
  __typename?: 'Score';
  license: Scalars['Float']['output'];
  maintenance: Scalars['Float']['output'];
  security: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type Severity = {
  __typename?: 'Severity';
  availability?: Maybe<SeverityAvailability>;
  complexity?: Maybe<SeverityComplexity>;
  integrity?: Maybe<SeverityIntegrity>;
  privileges?: Maybe<SeverityPrivileges>;
  score?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum SeverityAvailability {
  High = 'high',
  Low = 'low',
  None = 'none'
}

export enum SeverityComplexity {
  High = 'high',
  Low = 'low'
}

export enum SeverityIntegrity {
  High = 'high',
  Low = 'low',
  None = 'none'
}

export enum SeverityPrivileges {
  High = 'high',
  Low = 'low',
  None = 'none'
}

export type Stats = {
  __typename?: 'Stats';
  current: Scalars['Int']['output'];
  diff: Scalars['Int']['output'];
  history: Array<HistoryPoint>;
};

export type Subscription = {
  __typename?: 'Subscription';
  repositoryPreviewUpdated: RepositoryPreview;
  repositoryUpdated: Repository;
};


export type SubscriptionRepositoryUpdatedArgs = {
  id: Scalars['ID']['input'];
};

export type UpdatePackagesInput = {
  manifestPackageId: Scalars['ID']['input'];
  version: Scalars['String']['input'];
};

export type UpdateWorkspaceSettingsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpgradeStatus = {
  __typename?: 'UpgradeStatus';
  critical: Scalars['Int']['output'];
  outdated: Scalars['Int']['output'];
  security: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profileImgHref: Scalars['String']['output'];
};

export type Vulnerability = {
  __typename?: 'Vulnerability';
  aliases: Array<Scalars['String']['output']>;
  credits: Array<Credit>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  occurrence: VulnerabilityOccurence;
  published?: Maybe<Scalars['Time']['output']>;
  references: Array<VulnerabilityReference>;
  related: Array<Scalars['String']['output']>;
  severity: Severity;
  summary?: Maybe<Scalars['String']['output']>;
};

export type VulnerabilityOccurence = {
  __typename?: 'VulnerabilityOccurence';
  manifestPackageId: Scalars['ID']['output'];
  manifestType: ManifestType;
  patchedVersion: Scalars['String']['output'];
  path: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type VulnerabilityReference = {
  __typename?: 'VulnerabilityReference';
  type: ReferenceType;
  url: Scalars['String']['output'];
};

export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['ID']['output'];
  members: Array<WorkspaceMember>;
  name: Scalars['String']['output'];
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profileImgHref?: Maybe<Scalars['String']['output']>;
  role: WorkspaceMemberRole;
  status: WorkspaceMemberStatus;
};

export enum WorkspaceMemberRole {
  Admin = 'admin',
  Member = 'member'
}

export enum WorkspaceMemberStatus {
  Active = 'active',
  Invited = 'invited'
}

export const MemberFragmentFragmentDoc = gql`
    fragment memberFragment on WorkspaceMember {
  id
  name
  email
  profileImgHref
  role
  status
}
    `;
export const WorkspaceFragmentFragmentDoc = gql`
    fragment workspaceFragment on Workspace {
  id
  name
  members {
    ...memberFragment
  }
}
    ${MemberFragmentFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment userFragment on User {
  id
  email
  name
  profileImgHref
}
    `;
export const HistoryPointFragmentFragmentDoc = gql`
    fragment historyPointFragment on HistoryPoint {
  date
  value
}
    `;
export const StatsFragmentFragmentDoc = gql`
    fragment statsFragment on Stats {
  current
  history {
    ...historyPointFragment
  }
  diff
}
    ${HistoryPointFragmentFragmentDoc}`;
export const RepositoryPreviewFragmentFragmentDoc = gql`
    fragment repositoryPreviewFragment on RepositoryPreview {
  id
  name
  status {
    updated
    outdated
    critical
    security
  }
  state
}
    `;
export const PackageVersionFragmentFragmentDoc = gql`
    fragment packageVersionFragment on PackageVersion {
  id
  rawVersion
  changelog
  releasedAt
}
    `;
export const PackageFragmentFragmentDoc = gql`
    fragment packageFragment on Package {
  id
  name
  dataSource
  description
  readme
  homepage
  repository
  keywords
  maintainers
  score {
    security
    maintenance
    license
    total
  }
  usedIn {
    ...repositoryPreviewFragment
  }
  versions {
    ...packageVersionFragment
  }
  history {
    date
    value
  }
}
    ${RepositoryPreviewFragmentFragmentDoc}
${PackageVersionFragmentFragmentDoc}`;
export const PackagePreviewFragmentFragmentDoc = gql`
    fragment packagePreviewFragment on Package {
  id
  name
  description
  dataSource
  score {
    total
  }
}
    `;
export const DependencyFragmentFragmentDoc = gql`
    fragment dependencyFragment on Dependency {
  id
  state
  package {
    ...packagePreviewFragment
  }
  currentVersion {
    ...packageVersionFragment
  }
  upgrade {
    type
    version
    versions {
      ...packageVersionFragment
    }
  }
  pullRequest {
    title
    url
    createdAt
  }
  type
}
    ${PackagePreviewFragmentFragmentDoc}
${PackageVersionFragmentFragmentDoc}`;
export const RepositoryFragmentFragmentDoc = gql`
    fragment repositoryFragment on Repository {
  id
  name
  url
  settings {
    isDefault
    raw
  }
  status {
    updated
    outdated
    critical
    security
  }
  manifestFiles {
    status {
      updated
      outdated
      critical
      security
    }
    id
    manifestType
    path
    dependencies {
      ...dependencyFragment
    }
    isSupported
  }
}
    ${DependencyFragmentFragmentDoc}`;
export const VulnerabilityFragmentFragmentDoc = gql`
    fragment vulnerabilityFragment on Vulnerability {
  id
  name
  published
  occurrence {
    manifestPackageId
    manifestType
    path
    version
    patchedVersion
  }
  aliases
  related
  summary
  details
  severity {
    type
    complexity
    integrity
    availability
    privileges
    score
  }
  references {
    type
    url
  }
  credits {
    name
    contact
    type
  }
}
    `;
export const CreateWorkspaceDocument = gql`
    mutation createWorkspace($name: String!) {
  createWorkspace(name: $name) {
    ...workspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

export function useCreateWorkspaceMutation() {
  return Urql.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument);
};
export const InviteUsersToWorkspaceDocument = gql`
    mutation inviteUsersToWorkspace($emails: [String!]!) {
  inviteUsersToWorkspace(emails: $emails) {
    ...workspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

export function useInviteUsersToWorkspaceMutation() {
  return Urql.useMutation<InviteUsersToWorkspaceMutation, InviteUsersToWorkspaceMutationVariables>(InviteUsersToWorkspaceDocument);
};
export const RootQueryDocument = gql`
    query rootQuery {
  workspaces {
    ...workspaceFragment
  }
  workspace {
    ...workspaceFragment
  }
  user {
    ...userFragment
  }
  notifications
}
    ${WorkspaceFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;

export function useRootQueryQuery(options?: Omit<Urql.UseQueryArgs<RootQueryQueryVariables>, 'query'>) {
  return Urql.useQuery<RootQueryQuery, RootQueryQueryVariables>({ query: RootQueryDocument, ...options });
};
export const SelectWorkspaceDocument = gql`
    mutation selectWorkspace($id: ID!) {
  selectWorkspace(id: $id) {
    ...workspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

export function useSelectWorkspaceMutation() {
  return Urql.useMutation<SelectWorkspaceMutation, SelectWorkspaceMutationVariables>(SelectWorkspaceDocument);
};
export const AnalyticsDocument = gql`
    query analytics($repositoryId: ID, $days: Int!) {
  analytics(repositoryId: $repositoryId, days: $days) {
    updated {
      ...statsFragment
    }
    critical {
      ...statsFragment
    }
    security {
      ...statsFragment
    }
    historical {
      date
      updated
      outdated
      critical
      security
    }
  }
}
    ${StatsFragmentFragmentDoc}`;

export function useAnalyticsQuery(options: Omit<Urql.UseQueryArgs<AnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<AnalyticsQuery, AnalyticsQueryVariables>({ query: AnalyticsDocument, ...options });
};
export const LicenseDocument = gql`
    query license($name: String!) {
  license(name: $name) {
    package {
      ...packagePreviewFragment
    }
    repositoryName
    repositoryId
    license
    isAccepted
  }
}
    ${PackagePreviewFragmentFragmentDoc}`;

export function useLicenseQuery(options: Omit<Urql.UseQueryArgs<LicenseQueryVariables>, 'query'>) {
  return Urql.useQuery<LicenseQuery, LicenseQueryVariables>({ query: LicenseDocument, ...options });
};
export const LicensesDocument = gql`
    query licenses {
  licenses {
    name
    count
    compliance
  }
}
    `;

export function useLicensesQuery(options?: Omit<Urql.UseQueryArgs<LicensesQueryVariables>, 'query'>) {
  return Urql.useQuery<LicensesQuery, LicensesQueryVariables>({ query: LicensesDocument, ...options });
};
export const PackageDocument = gql`
    query package($id: ID!) {
  package(id: $id) {
    ...packageFragment
  }
}
    ${PackageFragmentFragmentDoc}`;

export function usePackageQuery(options: Omit<Urql.UseQueryArgs<PackageQueryVariables>, 'query'>) {
  return Urql.useQuery<PackageQuery, PackageQueryVariables>({ query: PackageDocument, ...options });
};
export const PackagesDocument = gql`
    query packages {
  packages {
    ...packagePreviewFragment
  }
}
    ${PackagePreviewFragmentFragmentDoc}`;

export function usePackagesQuery(options?: Omit<Urql.UseQueryArgs<PackagesQueryVariables>, 'query'>) {
  return Urql.useQuery<PackagesQuery, PackagesQueryVariables>({ query: PackagesDocument, ...options });
};
export const OrganizationsDocument = gql`
    query organizations {
  organizations {
    id
    name
    profileImgHref
    repositories {
      ...repositoryPreviewFragment
    }
  }
}
    ${RepositoryPreviewFragmentFragmentDoc}`;

export function useOrganizationsQuery(options?: Omit<Urql.UseQueryArgs<OrganizationsQueryVariables>, 'query'>) {
  return Urql.useQuery<OrganizationsQuery, OrganizationsQueryVariables>({ query: OrganizationsDocument, ...options });
};
export const RepositoryPreviewUpdatedDocument = gql`
    subscription repositoryPreviewUpdated {
  repositoryPreviewUpdated {
    ...repositoryPreviewFragment
  }
}
    ${RepositoryPreviewFragmentFragmentDoc}`;

export function useRepositoryPreviewUpdatedSubscription<TData = RepositoryPreviewUpdatedSubscription>(options?: Omit<Urql.UseSubscriptionArgs<RepositoryPreviewUpdatedSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<RepositoryPreviewUpdatedSubscription, TData>) {
  return Urql.useSubscription<RepositoryPreviewUpdatedSubscription, TData, RepositoryPreviewUpdatedSubscriptionVariables>({ query: RepositoryPreviewUpdatedDocument, ...options }, handler);
};
export const InitializeRepositoryDocument = gql`
    mutation initializeRepository($id: ID!) {
  initializeRepository(id: $id) {
    ...repositoryPreviewFragment
  }
}
    ${RepositoryPreviewFragmentFragmentDoc}`;

export function useInitializeRepositoryMutation() {
  return Urql.useMutation<InitializeRepositoryMutation, InitializeRepositoryMutationVariables>(InitializeRepositoryDocument);
};
export const RepositoryDocument = gql`
    query repository($id: ID!) {
  repository(id: $id) {
    ...repositoryFragment
  }
}
    ${RepositoryFragmentFragmentDoc}`;

export function useRepositoryQuery(options: Omit<Urql.UseQueryArgs<RepositoryQueryVariables>, 'query'>) {
  return Urql.useQuery<RepositoryQuery, RepositoryQueryVariables>({ query: RepositoryDocument, ...options });
};
export const VulnerabilitiesDocument = gql`
    query vulnerabilities($id: ID!) {
  repository(id: $id) {
    id
    vulnerabilities {
      ...vulnerabilityFragment
    }
  }
}
    ${VulnerabilityFragmentFragmentDoc}`;

export function useVulnerabilitiesQuery(options: Omit<Urql.UseQueryArgs<VulnerabilitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<VulnerabilitiesQuery, VulnerabilitiesQueryVariables>({ query: VulnerabilitiesDocument, ...options });
};
export const UpdatePackagesDocument = gql`
    mutation updatePackages($packages: [UpdatePackagesInput]!) {
  updatePackages(packages: $packages)
}
    `;

export function useUpdatePackagesMutation() {
  return Urql.useMutation<UpdatePackagesMutation, UpdatePackagesMutationVariables>(UpdatePackagesDocument);
};
export const RepositoryUpdatedDocument = gql`
    subscription repositoryUpdated($id: ID!) {
  repositoryUpdated(id: $id) {
    ...repositoryFragment
  }
}
    ${RepositoryFragmentFragmentDoc}`;

export function useRepositoryUpdatedSubscription<TData = RepositoryUpdatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<RepositoryUpdatedSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<RepositoryUpdatedSubscription, TData>) {
  return Urql.useSubscription<RepositoryUpdatedSubscription, TData, RepositoryUpdatedSubscriptionVariables>({ query: RepositoryUpdatedDocument, ...options }, handler);
};
export const UpdateWorkspaceSettingsDocument = gql`
    mutation updateWorkspaceSettings($settings: UpdateWorkspaceSettingsInput!) {
  updateWorkspaceSettings(settings: $settings) {
    ...workspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

export function useUpdateWorkspaceSettingsMutation() {
  return Urql.useMutation<UpdateWorkspaceSettingsMutation, UpdateWorkspaceSettingsMutationVariables>(UpdateWorkspaceSettingsDocument);
};
export const RevokeWorkspaceInvitationDocument = gql`
    mutation revokeWorkspaceInvitation($invitationId: ID!) {
  revokeWorkspaceInvitation(invitationId: $invitationId) {
    ...workspaceFragment
  }
}
    ${WorkspaceFragmentFragmentDoc}`;

export function useRevokeWorkspaceInvitationMutation() {
  return Urql.useMutation<RevokeWorkspaceInvitationMutation, RevokeWorkspaceInvitationMutationVariables>(RevokeWorkspaceInvitationDocument);
};
export type MemberFragmentFragment = { __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus };

export type WorkspaceFragmentFragment = { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> };

export type CreateWorkspaceMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace: { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> } };

export type InviteUsersToWorkspaceMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type InviteUsersToWorkspaceMutation = { __typename?: 'Mutation', inviteUsersToWorkspace: { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> } };

export type UserFragmentFragment = { __typename?: 'User', id: string, email: string, name: string, profileImgHref: string };

export type RootQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type RootQueryQuery = { __typename?: 'Query', notifications: number, workspaces: Array<{ __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> }>, workspace: { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> }, user: { __typename?: 'User', id: string, email: string, name: string, profileImgHref: string } };

export type SelectWorkspaceMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SelectWorkspaceMutation = { __typename?: 'Mutation', selectWorkspace: { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> } };

export type HistoryPointFragmentFragment = { __typename?: 'HistoryPoint', date: any, value: number };

export type StatsFragmentFragment = { __typename?: 'Stats', current: number, diff: number, history: Array<{ __typename?: 'HistoryPoint', date: any, value: number }> };

export type AnalyticsQueryVariables = Exact<{
  repositoryId?: InputMaybe<Scalars['ID']['input']>;
  days: Scalars['Int']['input'];
}>;


export type AnalyticsQuery = { __typename?: 'Query', analytics: { __typename?: 'Analytics', updated: { __typename?: 'Stats', current: number, diff: number, history: Array<{ __typename?: 'HistoryPoint', date: any, value: number }> }, critical: { __typename?: 'Stats', current: number, diff: number, history: Array<{ __typename?: 'HistoryPoint', date: any, value: number }> }, security: { __typename?: 'Stats', current: number, diff: number, history: Array<{ __typename?: 'HistoryPoint', date: any, value: number }> }, historical: Array<{ __typename?: 'HistoricalStats', date: any, updated: number, outdated: number, critical: number, security: number }> } };

export type LicenseQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type LicenseQuery = { __typename?: 'Query', license: Array<{ __typename?: 'LicensePackage', repositoryName: string, repositoryId: string, license: string, isAccepted: boolean, package: { __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } } }> };

export type LicensesQueryVariables = Exact<{ [key: string]: never; }>;


export type LicensesQuery = { __typename?: 'Query', licenses: Array<{ __typename?: 'LicenseInfo', name: string, count: number, compliance: boolean }> };

export type PackageFragmentFragment = { __typename?: 'Package', id: string, name: string, dataSource: DataSource, description: string, readme: string, homepage: string, repository: string, keywords: Array<string>, maintainers: Array<string>, score: { __typename?: 'Score', security: number, maintenance: number, license: number, total: number }, usedIn: Array<{ __typename?: 'RepositoryPreview', id: string, name: string, state: RepositoryState, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number } }>, versions: Array<{ __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }>, history: Array<{ __typename?: 'HistoryPoint', date: any, value: number }> };

export type PackageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PackageQuery = { __typename?: 'Query', package: { __typename?: 'Package', id: string, name: string, dataSource: DataSource, description: string, readme: string, homepage: string, repository: string, keywords: Array<string>, maintainers: Array<string>, score: { __typename?: 'Score', security: number, maintenance: number, license: number, total: number }, usedIn: Array<{ __typename?: 'RepositoryPreview', id: string, name: string, state: RepositoryState, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number } }>, versions: Array<{ __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }>, history: Array<{ __typename?: 'HistoryPoint', date: any, value: number }> } };

export type PackagePreviewFragmentFragment = { __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } };

export type PackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type PackagesQuery = { __typename?: 'Query', packages: Array<{ __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } }> };

export type RepositoryPreviewFragmentFragment = { __typename?: 'RepositoryPreview', id: string, name: string, state: RepositoryState, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number } };

export type OrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', id: string, name: string, profileImgHref: string, repositories: Array<{ __typename?: 'RepositoryPreview', id: string, name: string, state: RepositoryState, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number } }> }> };

export type RepositoryPreviewUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RepositoryPreviewUpdatedSubscription = { __typename?: 'Subscription', repositoryPreviewUpdated: { __typename?: 'RepositoryPreview', id: string, name: string, state: RepositoryState, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number } } };

export type InitializeRepositoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type InitializeRepositoryMutation = { __typename?: 'Mutation', initializeRepository: { __typename?: 'RepositoryPreview', id: string, name: string, state: RepositoryState, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number } } };

export type RepositoryFragmentFragment = { __typename?: 'Repository', id: string, name: string, url: string, settings: { __typename?: 'RepositorySettings', isDefault: boolean, raw: string }, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number }, manifestFiles: Array<{ __typename?: 'Manifest', id: string, manifestType: ManifestType, path: string, isSupported: boolean, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number }, dependencies: Array<{ __typename?: 'Dependency', id: string, state: DependencyState, type: DependencyType, package: { __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } }, currentVersion: { __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }, upgrade?: { __typename?: 'DependencyUpgrade', type: DependencyUpgradeType, version: string, versions: Array<{ __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }> } | null, pullRequest?: { __typename?: 'PullRequest', title: string, url: string, createdAt: any } | null }> }> };

export type VulnerabilityFragmentFragment = { __typename?: 'Vulnerability', id: string, name: string, published?: any | null, aliases: Array<string>, related: Array<string>, summary?: string | null, details?: string | null, occurrence: { __typename?: 'VulnerabilityOccurence', manifestPackageId: string, manifestType: ManifestType, path: string, version: string, patchedVersion: string }, severity: { __typename?: 'Severity', type?: string | null, complexity?: SeverityComplexity | null, integrity?: SeverityIntegrity | null, availability?: SeverityAvailability | null, privileges?: SeverityPrivileges | null, score?: number | null }, references: Array<{ __typename?: 'VulnerabilityReference', type: ReferenceType, url: string }>, credits: Array<{ __typename?: 'Credit', name?: string | null, contact: Array<string>, type?: string | null }> };

export type PackageVersionFragmentFragment = { __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any };

export type DependencyFragmentFragment = { __typename?: 'Dependency', id: string, state: DependencyState, type: DependencyType, package: { __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } }, currentVersion: { __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }, upgrade?: { __typename?: 'DependencyUpgrade', type: DependencyUpgradeType, version: string, versions: Array<{ __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }> } | null, pullRequest?: { __typename?: 'PullRequest', title: string, url: string, createdAt: any } | null };

export type RepositoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RepositoryQuery = { __typename?: 'Query', repository: { __typename?: 'Repository', id: string, name: string, url: string, settings: { __typename?: 'RepositorySettings', isDefault: boolean, raw: string }, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number }, manifestFiles: Array<{ __typename?: 'Manifest', id: string, manifestType: ManifestType, path: string, isSupported: boolean, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number }, dependencies: Array<{ __typename?: 'Dependency', id: string, state: DependencyState, type: DependencyType, package: { __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } }, currentVersion: { __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }, upgrade?: { __typename?: 'DependencyUpgrade', type: DependencyUpgradeType, version: string, versions: Array<{ __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }> } | null, pullRequest?: { __typename?: 'PullRequest', title: string, url: string, createdAt: any } | null }> }> } };

export type VulnerabilitiesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type VulnerabilitiesQuery = { __typename?: 'Query', repository: { __typename?: 'Repository', id: string, vulnerabilities: Array<{ __typename?: 'Vulnerability', id: string, name: string, published?: any | null, aliases: Array<string>, related: Array<string>, summary?: string | null, details?: string | null, occurrence: { __typename?: 'VulnerabilityOccurence', manifestPackageId: string, manifestType: ManifestType, path: string, version: string, patchedVersion: string }, severity: { __typename?: 'Severity', type?: string | null, complexity?: SeverityComplexity | null, integrity?: SeverityIntegrity | null, availability?: SeverityAvailability | null, privileges?: SeverityPrivileges | null, score?: number | null }, references: Array<{ __typename?: 'VulnerabilityReference', type: ReferenceType, url: string }>, credits: Array<{ __typename?: 'Credit', name?: string | null, contact: Array<string>, type?: string | null }> }> } };

export type UpdatePackagesMutationVariables = Exact<{
  packages: Array<InputMaybe<UpdatePackagesInput>> | InputMaybe<UpdatePackagesInput>;
}>;


export type UpdatePackagesMutation = { __typename?: 'Mutation', updatePackages: boolean };

export type RepositoryUpdatedSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RepositoryUpdatedSubscription = { __typename?: 'Subscription', repositoryUpdated: { __typename?: 'Repository', id: string, name: string, url: string, settings: { __typename?: 'RepositorySettings', isDefault: boolean, raw: string }, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number }, manifestFiles: Array<{ __typename?: 'Manifest', id: string, manifestType: ManifestType, path: string, isSupported: boolean, status: { __typename?: 'UpgradeStatus', updated: number, outdated: number, critical: number, security: number }, dependencies: Array<{ __typename?: 'Dependency', id: string, state: DependencyState, type: DependencyType, package: { __typename?: 'Package', id: string, name: string, description: string, dataSource: DataSource, score: { __typename?: 'Score', total: number } }, currentVersion: { __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }, upgrade?: { __typename?: 'DependencyUpgrade', type: DependencyUpgradeType, version: string, versions: Array<{ __typename?: 'PackageVersion', id?: string | null, rawVersion: string, changelog: string, releasedAt: any }> } | null, pullRequest?: { __typename?: 'PullRequest', title: string, url: string, createdAt: any } | null }> }> } };

export type UpdateWorkspaceSettingsMutationVariables = Exact<{
  settings: UpdateWorkspaceSettingsInput;
}>;


export type UpdateWorkspaceSettingsMutation = { __typename?: 'Mutation', updateWorkspaceSettings: { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> } };

export type RevokeWorkspaceInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID']['input'];
}>;


export type RevokeWorkspaceInvitationMutation = { __typename?: 'Mutation', revokeWorkspaceInvitation: { __typename?: 'Workspace', id: string, name: string, members: Array<{ __typename?: 'WorkspaceMember', id: string, name?: string | null, email: string, profileImgHref?: string | null, role: WorkspaceMemberRole, status: WorkspaceMemberStatus }> } };

import { IntrospectionQuery } from 'graphql';
export default {
  "__schema": {
    "queryType": {
      "name": "Query"
    },
    "mutationType": {
      "name": "Mutation"
    },
    "subscriptionType": {
      "name": "Subscription"
    },
    "types": [
      {
        "kind": "OBJECT",
        "name": "Analytics",
        "fields": [
          {
            "name": "critical",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Stats",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "historical",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "HistoricalStats",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "security",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Stats",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "updated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Stats",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Credit",
        "fields": [
          {
            "name": "contact",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Dependency",
        "fields": [
          {
            "name": "currentVersion",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "PackageVersion",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "package",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Package",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "pullRequest",
            "type": {
              "kind": "OBJECT",
              "name": "PullRequest",
              "ofType": null
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "upgrade",
            "type": {
              "kind": "OBJECT",
              "name": "DependencyUpgrade",
              "ofType": null
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "DependencyUpgrade",
        "fields": [
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "version",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "versions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "PackageVersion",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "HistoricalStats",
        "fields": [
          {
            "name": "critical",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "date",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "outdated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "security",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "updated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "HistoryPoint",
        "fields": [
          {
            "name": "date",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "value",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LicenseInfo",
        "fields": [
          {
            "name": "compliance",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "count",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "LicensePackage",
        "fields": [
          {
            "name": "isAccepted",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "license",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "package",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Package",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "repositoryId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "repositoryName",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Manifest",
        "fields": [
          {
            "name": "dependencies",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Dependency",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "isSupported",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "manifestType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "path",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "UpgradeStatus",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Mutation",
        "fields": [
          {
            "name": "createWorkspace",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Workspace",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "name",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "initializeRepository",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "RepositoryPreview",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "inviteUsersToWorkspace",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Workspace",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "emails",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "NON_NULL",
                      "ofType": {
                        "kind": "SCALAR",
                        "name": "Any"
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "revokeWorkspaceInvitation",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Workspace",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "invitationId",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "selectWorkspace",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Workspace",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "updatePackages",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": [
              {
                "name": "packages",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "LIST",
                    "ofType": {
                      "kind": "SCALAR",
                      "name": "Any"
                    }
                  }
                }
              }
            ]
          },
          {
            "name": "updateWorkspaceSettings",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Workspace",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "settings",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Organization",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "profileImgHref",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "repositories",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "RepositoryPreview",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Package",
        "fields": [
          {
            "name": "dataSource",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "description",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "history",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "HistoryPoint",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "homepage",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "keywords",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "maintainers",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "readme",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "repository",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "score",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Score",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "usedIn",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "RepositoryPreview",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "versions",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "PackageVersion",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "PackageVersion",
        "fields": [
          {
            "name": "changelog",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "rawVersion",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "releasedAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "PullRequest",
        "fields": [
          {
            "name": "createdAt",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "title",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "url",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Query",
        "fields": [
          {
            "name": "analytics",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Analytics",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "days",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              },
              {
                "name": "repositoryId",
                "type": {
                  "kind": "SCALAR",
                  "name": "Any"
                }
              }
            ]
          },
          {
            "name": "license",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "LicensePackage",
                    "ofType": null
                  }
                }
              }
            },
            "args": [
              {
                "name": "name",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "licenses",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "LicenseInfo",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "notifications",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "organizations",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Organization",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "package",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Package",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "packages",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Package",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "repository",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Repository",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          },
          {
            "name": "user",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "User",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "workspace",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Workspace",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "workspaces",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Workspace",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Repository",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "manifestFiles",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Manifest",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "settings",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "RepositorySettings",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "UpgradeStatus",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "url",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "vulnerabilities",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Vulnerability",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": [
          {
            "kind": "INTERFACE",
            "name": "RepositoryBase"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "RepositoryBase",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "UpgradeStatus",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": [],
        "possibleTypes": [
          {
            "kind": "OBJECT",
            "name": "Repository"
          },
          {
            "kind": "OBJECT",
            "name": "RepositoryPreview"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "RepositoryPreview",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "state",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "UpgradeStatus",
                "ofType": null
              }
            },
            "args": []
          }
        ],
        "interfaces": [
          {
            "kind": "INTERFACE",
            "name": "RepositoryBase"
          }
        ]
      },
      {
        "kind": "OBJECT",
        "name": "RepositorySettings",
        "fields": [
          {
            "name": "isDefault",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "raw",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Score",
        "fields": [
          {
            "name": "license",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "maintenance",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "security",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "total",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Severity",
        "fields": [
          {
            "name": "availability",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "complexity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "integrity",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "privileges",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "score",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "type",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Stats",
        "fields": [
          {
            "name": "current",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "diff",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "history",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "HistoryPoint",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Subscription",
        "fields": [
          {
            "name": "repositoryPreviewUpdated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "RepositoryPreview",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "repositoryUpdated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Repository",
                "ofType": null
              }
            },
            "args": [
              {
                "name": "id",
                "type": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            ]
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "UpgradeStatus",
        "fields": [
          {
            "name": "critical",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "outdated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "security",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "updated",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "User",
        "fields": [
          {
            "name": "email",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "profileImgHref",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Vulnerability",
        "fields": [
          {
            "name": "aliases",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "credits",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "Credit",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "details",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "occurrence",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "VulnerabilityOccurence",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "published",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "references",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "VulnerabilityReference",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "related",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "SCALAR",
                    "name": "Any"
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "severity",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "OBJECT",
                "name": "Severity",
                "ofType": null
              }
            },
            "args": []
          },
          {
            "name": "summary",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "VulnerabilityOccurence",
        "fields": [
          {
            "name": "manifestPackageId",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "manifestType",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "patchedVersion",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "path",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "version",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "VulnerabilityReference",
        "fields": [
          {
            "name": "type",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "url",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "Workspace",
        "fields": [
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "members",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "LIST",
                "ofType": {
                  "kind": "NON_NULL",
                  "ofType": {
                    "kind": "OBJECT",
                    "name": "WorkspaceMember",
                    "ofType": null
                  }
                }
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "OBJECT",
        "name": "WorkspaceMember",
        "fields": [
          {
            "name": "email",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "id",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "name",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "profileImgHref",
            "type": {
              "kind": "SCALAR",
              "name": "Any"
            },
            "args": []
          },
          {
            "name": "role",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          },
          {
            "name": "status",
            "type": {
              "kind": "NON_NULL",
              "ofType": {
                "kind": "SCALAR",
                "name": "Any"
              }
            },
            "args": []
          }
        ],
        "interfaces": []
      },
      {
        "kind": "SCALAR",
        "name": "Any"
      }
    ],
    "directives": []
  }
} as unknown as IntrospectionQuery;