import { flexRender } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { PackageHoverCard } from "@/components/HoverCard/Package/Package";
import { Icon } from "@/components/Icon/Icon";
import {
  nonClickableTrStyles,
  tdStyles,
  thStyles,
  trStyles,
  wrapperStyles,
} from "@/components/Table/Table.css";
import { VersionPill } from "@/components/VersionPill/VersionPill";
import {
  LicenseQuery,
  PackagePreviewFragmentFragment,
} from "@/generated/graphql";

import {
  clickablePillStyles,
  complianceStyles,
  nameStyles,
} from "./License.css";

type LicensesTableProps = {
  data?: LicenseQuery;
};

type PackageRow = {
  repositoryName: string;
  repositoryId: string;
  license: string;
  isAccepted: boolean;
  package: PackagePreviewFragmentFragment;
};

const columnHelper = createColumnHelper<PackageRow>();

const columns = [
  columnHelper.accessor("package", {
    header: () => <>Package name</>,
    cell: ({ row }) => (
      <div className={nameStyles}>
        <PackageHoverCard
          name={row.original.package.name}
          id={row.original.package.id}
          description={row.original.package.description}
          score={row.original.package.score.total}
          dataSource={row.original.package.dataSource}
        />
      </div>
    ),
    maxSize: 100,
    size: 100,
    enableGrouping: true,
  }),
  columnHelper.accessor("repositoryName", {
    header: () => <>Repository name</>,
    cell: ({ row }) => (
      <div className={complianceStyles}>
        <Link to={"/repositories/" + row.original.repositoryId}>
          <VersionPill
            version={row.original.repositoryName}
            icon={<Icon.Github />}
            className={clickablePillStyles}
          />
        </Link>
      </div>
    ),
  }),
  columnHelper.accessor("license", {
    header: () => <>License</>,
    cell: ({ row }) => (
      <div className={complianceStyles}>{row.original.license}</div>
    ),
  }),
  columnHelper.accessor("isAccepted", {
    header: () => <>Compliance</>,
    cell: ({ row }) => (
      <div className={complianceStyles}>
        <VersionPill
          version={row.original.isAccepted ? "Yes" : "No"}
          type={row.original.isAccepted ? "success" : "error"}
        />
      </div>
    ),
  }),
];

export const LicenseTable = ({ data }: LicensesTableProps) => {
  const table = useReactTable({
    data: data?.license ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugAll: false,
  });

  return (
    <table className={wrapperStyles}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={thStyles}
                key={header.id}
                style={{
                  width: header.column.columnDef.size,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, i) => {
          return (
            <tr key={i} className={classNames(trStyles, nonClickableTrStyles)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={tdStyles}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
