import { formatDistance } from "date-fns";

import {
  SeverityAvailability,
  SeverityComplexity,
  SeverityIntegrity,
  SeverityPrivileges,
} from "@/generated/graphql";

export const formatPublished = (published: string) =>
  formatDistance(new Date(published), new Date(), { addSuffix: true });

export const formatComplexity = (complexity?: SeverityComplexity | null) => {
  switch (complexity) {
    case SeverityComplexity.Low:
      return "Low";
    case SeverityComplexity.High:
      return "High";
    default:
      return "Unknown";
  }
};

export const formatIntegrity = (integrity?: SeverityIntegrity | null) => {
  switch (integrity) {
    case SeverityIntegrity.Low:
      return "Low";
    case SeverityIntegrity.High:
      return "High";
    case SeverityIntegrity.None:
      return "None";
    default:
      return "Unknown";
  }
};

export const formatAvailability = (
  availability?: SeverityAvailability | null
) => {
  switch (availability) {
    case SeverityAvailability.Low:
      return "Low";
    case SeverityAvailability.High:
      return "High";
    case SeverityAvailability.None:
      return "None";
    default:
      return "Unknown";
  }
};

export const formatPrivileges = (privileges?: SeverityPrivileges | null) => {
  switch (privileges) {
    case SeverityPrivileges.Low:
      return "Low";
    case SeverityPrivileges.High:
      return "High";
    case SeverityPrivileges.None:
      return "None";
    default:
      return "Unknown";
  }
};
